.btn-primary {
  background: #c94e29;
  color: #fff;
  border: 2px solid #c94e29;
  font-family: "Roboto" sans-serif;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
  background: #9f72b1 !important;
  border-color: #9f72b1 !important;
}
.btn-primary.btn-outline {
  background: transparent;
  color: #c94e29;
  border: 2px solid #c94e29;
}
.btn-primary.btn-outline:hover,
.btn-primary.btn-outline:focus,
.btn-primary.btn-outline:active {
  background: #c94e29;
  color: #fff;
}

.btn {
  margin-right: 4px;
  margin-bottom: 4px;
  font-family: "Roboto", Arial, sans-serif;
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 1px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  border-radius: 0px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  padding: 8px 20px !important;
}
.btn.btn-sm {
  padding: 4px 15px !important;
}
.btn.btn-md {
  padding: 8px 20px !important;
}
.btn.btn-lg {
  padding: 18px 36px !important;
}
.btn:hover,
.btn:active,
.btn:focus {
  box-shadow: none !important;
  outline: none !important;
}

.btn-success {
  background: #5cb85c;
  color: #fff;
  border: 2px solid #5cb85c;
}
.btn-success:hover,
.btn-success:focus,
.btn-success:active {
  background: #4cae4c !important;
  border-color: #4cae4c !important;
}
.btn-success.btn-outline {
  background: transparent;
  color: #5cb85c;
  border: 2px solid #5cb85c;
}
.btn-success.btn-outline:hover,
.btn-success.btn-outline:focus,
.btn-success.btn-outline:active {
  background: #5cb85c;
  color: #fff;
}

.btn-info {
  background: #5bc0de;
  color: #fff;
  border: 2px solid #5bc0de;
}
.btn-info:hover,
.btn-info:focus,
.btn-info:active {
  background: #46b8da !important;
  border-color: #46b8da !important;
}
.btn-info.btn-outline {
  background: transparent;
  color: #5bc0de;
  border: 2px solid #5bc0de;
}
.btn-info.btn-outline:hover,
.btn-info.btn-outline:focus,
.btn-info.btn-outline:active {
  background: #5bc0de;
  color: #fff;
}

.btn-warning {
  background: #f0ad4e;
  color: #fff;
  border: 2px solid #f0ad4e;
}
.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active {
  background: #eea236 !important;
  border-color: #eea236 !important;
}
.btn-warning.btn-outline {
  background: transparent;
  color: #f0ad4e;
  border: 2px solid #f0ad4e;
}
.btn-warning.btn-outline:hover,
.btn-warning.btn-outline:focus,
.btn-warning.btn-outline:active {
  background: #f0ad4e;
  color: #fff;
}

.btn-danger {
  background: #d9534f;
  color: #fff;
  border: 2px solid #d9534f;
}
.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active {
  background: #d43f3a !important;
  border-color: #d43f3a !important;
}
.btn-danger.btn-outline {
  background: transparent;
  color: #d9534f;
  border: 2px solid #d9534f;
}
.btn-danger.btn-outline:hover,
.btn-danger.btn-outline:focus,
.btn-danger.btn-outline:active {
  background: #d9534f;
  color: #fff;
}

.btn-outline {
  background: none;
  border: 2px solid gray;
  font-size: 16px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.btn-outline:hover,
.btn-outline:focus,
.btn-outline:active {
  box-shadow: none;
}
/* =======================================================
*
* 	Template Style 
*	Edit this section
*
* ======================================================= */
body {
  font-family: "Roboto", Arial, sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.6;
  color: rgba(0, 0, 0, 0.5);
  background: whitesmoke;
  height: 100%;
}
@media screen and (max-width: 992px) {
  body {
    font-size: 16px;
  }
}

a {
  color: #f13d06;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
a:hover,
a:active,
a:focus {
  color: #c94e29;
  outline: none;
  text-decoration: none !important;
}

p {
  margin-bottom: 1.5em;
  font-weight: 300;
  font-size: 15px;
  color: #000;
}

h1,
h2,
h3,
h4,
h5 {
  color: #000;
  font-family: "Roboto", Arial, sans-serif;
  font-weight: 400;
  margin: 0 0 30px 0;
}

h6 {
  font-family: "Roboto", Arial, sans-serif;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.5);
}

figure {
  margin-bottom: 2.5em;
  float: left;
  width: 100%;
}
figure figcaption {
  font-size: 16px;
  width: 80%;
  margin: 20px auto 0px auto;
  color: #b3b3b3;
  font-style: italic;
  font-family: "Roboto", Arial, sans-serif;
}
@media screen and (max-width: 480px) {
  figure figcaption {
    width: 100%;
  }
}

::-webkit-selection {
  color: #fff;
  background: #c94e29;
}

::-moz-selection {
  color: #fff;
  background: #c94e29;
}

::selection {
  color: #fff;
  background: #c94e29;
}

#fh5co-page {
  width: 100%;
  overflow: hidden;
  position: relative;
}

#fh5co-main {
  width: 80%;
  float: right;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
@media screen and (max-width: 1200px) {
  #fh5co-main {
    width: 70%;
  }
}
@media screen and (max-width: 768px) {
  #fh5co-main {
    width: 100%;
  }
}
#fh5co-main .fh5co-narrow-content {
  position: relative;
  width: 93%;
  margin: 0 auto;
  padding: 4em 0;
  clear: both;
}
@media screen and (max-width: 768px) {
  #fh5co-main .fh5co-narrow-content {
    width: 100%;
    padding: 4em 1em;
  }
}

.fh5co-bg-color {
  width: 100%;
  float: left;
  background: #fff;
}

body.offcanvas1 {
  overflow-x: hidden;
}
body.offcanvas1 #fh5co-aside {
  -moz-transform: translateX(0);
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
  width: 270px;
  background: #f5f5f5;
  z-index: 999;
  position: fixed;
}
body.offcanvas1 #fh5co-main,
body.offcanvas1 .fh5co-nav-toggle {
  top: 0;
  -moz-transform: translateX(270px);
  -webkit-transform: translateX(270px);
  -ms-transform: translateX(270px);
  -o-transform: translateX(270px);
  transform: translateX(270px);
}

.fh5co-nav-toggle {
  cursor: pointer;
  text-decoration: none;
}
.fh5co-nav-toggle.active i::before,
.fh5co-nav-toggle.active i::after {
  background: #c94e29;
}
.fh5co-nav-toggle.dark.active i::before,
.fh5co-nav-toggle.dark.active i::after {
  background: #000;
}
.fh5co-nav-toggle:hover,
.fh5co-nav-toggle:focus,
.fh5co-nav-toggle:active {
  outline: none;
  border-bottom: none !important;
}
.fh5co-nav-toggle i {
  position: relative;
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
  width: 30px;
  height: 2px;
  color: #c94e29;
  font: bold 14px/0.4 Helvetica;
  text-transform: uppercase;
  text-indent: -55px;
  background: #c94e29;
  transition: all 0.2s ease-out;
}
.fh5co-nav-toggle i::before,
.fh5co-nav-toggle i::after {
  content: "";
  width: 30px;
  height: 2px;
  background: #c94e29;
  position: absolute;
  left: 0;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}
.fh5co-nav-toggle.dark i {
  position: relative;
  color: #c94e29;
  background: #c94e29;
  transition: all 0.2s ease-out;
}
.fh5co-nav-toggle.dark i::before,
.fh5co-nav-toggle.dark i::after {
  background: #000;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}

.fh5co-nav-toggle i::before {
  top: -7px;
}

.fh5co-nav-toggle i::after {
  bottom: -7px;
}

.fh5co-nav-toggle:hover i::before {
  top: -10px;
}

.fh5co-nav-toggle:hover i::after {
  bottom: -10px;
}

.fh5co-nav-toggle.active i {
  background: transparent;
}

.fh5co-nav-toggle.active i::before {
  top: 0;
  -webkit-transform: rotateZ(45deg);
  -moz-transform: rotateZ(45deg);
  -ms-transform: rotateZ(45deg);
  -o-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
}

.fh5co-nav-toggle.active i::after {
  bottom: 0;
  -webkit-transform: rotateZ(-45deg);
  -moz-transform: rotateZ(-45deg);
  -ms-transform: rotateZ(-45deg);
  -o-transform: rotateZ(-45deg);
  transform: rotateZ(-45deg);
}

.fh5co-nav-toggle {
  position: fixed;
  left: 0;
  top: 0px;
  z-index: 9999;
  cursor: pointer;
  opacity: 1;
  visibility: hidden;
  padding: 20px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
@media screen and (max-width: 768px) {
  .fh5co-nav-toggle {
    opacity: 1;
    visibility: visible;
  }
}

.fh5co-services ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.fh5co-lead {
  font-size: 18px;
  line-height: 1.5;
}

.fh5co-heading-colored {
  color: #c94e29;
  font-size: 30px;
}

.fh5co-heading {
  font-size: 18px;
  margin-bottom: 2em;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.fh5co-heading.fh5co-light {
  color: #fff;
}
.fh5co-heading span {
  display: block;
}
@media screen and (max-width: 768px) {
  .fh5co-heading {
    margin-bottom: 1em;
  }
}
@media screen and (max-width: 480px) {
  .col-xxs-12 {
    float: none;
    width: 100%;
  }
}

.row-bottom-padded-lg {
  padding-bottom: 7em;
}
@media screen and (max-width: 768px) {
  .row-bottom-padded-lg {
    padding-bottom: 1em;
  }
}

.row-bottom-padded-md {
  padding-bottom: 4em;
}
@media screen and (max-width: 768px) {
  .row-bottom-padded-md {
    padding-bottom: 1em;
  }
}

.row-bottom-padded-sm {
  padding-bottom: 1em;
}
@media screen and (max-width: 768px) {
  .row-bottom-padded-sm {
    padding-bottom: 1em;
  }
}

.col-padding {
  padding: 10px !important;
}

.js .animate-box {
  opacity: 0;
}

.fh5co-feature {
  text-align: left;
  width: 100%;
  float: left;
  margin-bottom: 40px;
  position: relative;
}
.fh5co-feature .fh5co-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
  display: table;
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}
.fh5co-feature .fh5co-icon i {
  display: table-cell;
  vertical-align: middle;
  color: #c94e29;
  font-size: 40px;
  height: 100px;
}
@media screen and (max-width: 1200px) {
  .fh5co-feature .fh5co-icon i {
    font-size: 40px;
  }
}
.fh5co-feature .fh5co-text {
  padding-left: 120px;
  width: 100%;
  font-family: "Roboto", Arial, sans-serif;
  font-style: normal;
}

.fh5co-feature .btn-service {
  font-size: 10px;
  border-radius: 15px;
  padding-inline: 5px;
}

.fh5co-feature .fh5co-text h2,
.fh5co-feature .fh5co-text h3 {
  margin: 0;
  padding: 0;
}
.fh5co-feature .fh5co-text p {
  opacity: 50%;
}
.fh5co-feature .fh5co-text h3 {
  font-weight: 500;
  margin-bottom: 20px;
  color: rgba(0, 0, 0, 0.8);
  font-size: 14px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}
.fh5co-feature.fh5co-feature-sm .fh5co-text {
  margin-top: 30px;
}
.fh5co-feature.fh5co-feature-sm .fh5co-icon i {
  color: #c94e29;
  font-size: 40px;
}
@media screen and (max-width: 1200px) {
  .fh5co-feature.fh5co-feature-sm .fh5co-icon i {
    font-size: 28px;
  }
}
.why_section {
  background-color: #8d5d7b;
  position: relative;
}

.why_section .h-tag {
  text-align: center;
  margin: 8px;
  color: whitesmoke;
  opacity: 0.9;
}

.why_section .p-tag {
  color: white;
  margin: 0;
  opacity: 0.9;
}

code {
  background-color: #616161;
  color: #616161;
}

pre {
  background: #0d0d0d;
  color: #fff;
  font-family: "JetBrainsMono", monospace;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
}
pre code {
  color: inherit;
  padding: 0;
  background: none;
  font-size: 0.8rem;
}

.centered-fn {
  align-items: center;
  justify-content: center;
  display: flex;
}

/* Contact Page Update   CSS Begining*/
.annotationLayer, .textLayer{
  display: none!important;
}
.document-container{
  padding: 30px;
}
.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 80% !important;
  height: 100% !important;
}


.policy-link-container{
  text-align: center;
  margin-bottom: 20px;
}
.policy-link{
  font-size: 18px;
  text-decoration: none;
  font-weight: 400;
  color: #a0a0a0;
  transition: 300ms;
}
.policy-link:hover{
  text-decoration: underline;
  opacity: 0.8;
}
.contact-cards{
  display: flex;
  flex-wrap: wrap;
}
.contact-card{
  padding: 20px 50px;
  border: 2px solid #c94e29;
  border-radius: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 20px 30px;
  
}
.contact-card .card-title-container{
  width: 100%;
  text-align: center;
}
.contact-card .card-title{
  font-size: large;
  font-weight: 600;
  display: inline-block;
  color: black;
  background-color: pink;
  transform: translateY(-35px);
  padding: 0 10px;
  background-color: #fafafa;
}
.contact-card .card-contents{
  display: flex;
  align-items: top;
  justify-content: center;
  flex-wrap: wrap;
}
.contact-card .card-content{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
  max-width: 150px;
}
.card-content .card-icon{
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 50%;
}
.contact-card .card-icon i{
    color: #c94e29;
    font-size: 40px;
}
.contact-card .card-text{
  margin-top: 10px;
}
.card-content .card-text .sub-title{
  text-align: center;
  font-size: large;
  color: #c94e29;

  font-weight: 500;
}
.contact-card .card-text a{
  font-weight: 400;
  font-size: large;
  color: black;
  text-decoration: none;
  text-align: center;
}
/* Contact Page Update   CSS End*/


/* Woman In Tech  CSS Begining*/
.women-in-tech{

}
.women-in-tech.main{
  margin-bottom: 64px;
  box-sizing: border-box;
  color: white;
}
.women-in-tech .bg-image {
  box-sizing: border-box;
  position: absolute;
  top: 56;
  left: 0;
  z-index: -1;
  width: 100%;
  height: calc(100% - 56px);
  object-fit: cover; /* Resim boyutlarını bozmadan tamamen kaplamak için */
}
.women-in-tech.main .content{
  padding: 28px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.women-in-tech.main .content .title-container{
  display: flex;
  align-items: center;
  flex-direction: column;
  width: max-content;
  max-width: 100%;

}
.women-in-tech.main .content .title-container .logo-container{
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  flex-wrap: wrap;
}
.women-in-tech.main .content .title-container .logo-container .middle-line{
  background-color: white;
  width: 3px;
  border-radius: 3px;
  height: 40px;
  margin: 0 40px;

}
.women-in-tech.main .content .title-container .logo-container .logo{
 height: 64px;
 width: auto; 
}
.women-in-tech.main .content .title-container .title{
  font-weight: bold;
  font-size: 36px;
  margin: 8px 0;
}
.women-in-tech.main .content .title-container .description{
  font-weight: bold;
  font-size: 24px;
  text-align: center;
}
.women-in-tech.main .content .description-container{
  font-size: 20px;
  text-align: center;
  margin: 20px 0;
}
.women-in-tech.main .signup-button-container{
  display: flex;
  justify-content: flex-end;
}
.women-in-tech.main .signup-button-container .link{
  color: white;
  text-decoration: none;
  cursor: pointer;
}
.women-in-tech.main .signup-button-container .button{
  font-size: 20px;
  font-weight: 500;
  padding: 7px 13px;
  background-color: white;
  border-radius: 10px;
  border: 1px solid white;
  transition: 300ms;
  color: #801591;
}
.women-in-tech.main .signup-button-container .button:hover{
  background-color: #801591;
  color: white;

}
.women-in-tech .custom-stepper{

}
.women-in-tech .custom-stepper .MuiSvgIcon-root{
  font-size: 30px!important;
  color: #663690;
}
.women-in-tech .Mui-completed  .MuiSvgIcon-root{
  font-size: 30px!important;
  color: #422166;
}
.women-in-tech .custom-stepper .steps-container .steps-title{
  font-weight: 600;
  font-size: 18px;
}
.women-in-tech.contents-container{
  display: flex;
  flex-direction: column;
  margin: 50px 0;
}
.women-in-tech.contents-container .content-container{
  width: 100%;
  display: flex;
}
.women-in-tech.contents-container .content-container:nth-of-type(even ){
  justify-content: flex-end;
}
.women-in-tech.contents-container .content-container .content-card{
  display: flex;
}
.women-in-tech.contents-container .content-container:nth-of-type(even ) .content-card{
  flex-direction: row-reverse;
}
.women-in-tech.contents-container .content-container .content-card .image-container{
  margin-right: 20px;
  border-radius: 10px;
} 
.women-in-tech.contents-container .content-container:nth-of-type(even ) .content-card .image-container{
  flex-direction: row-reverse;
  margin-left: 20px;
  margin-right: 0;
  

}
.women-in-tech.contents-container .content-container .content-card .image-container .image{
  width: 125px;
}
.women-in-tech.contents-container .content-container .content-card .title-content-container{
  width: calc(100% - 145px);
}
.women-in-tech.contents-container .content-container .content-card .title-content-container .title{
  color:#422166 ;
  font-weight: 600;
  font-size: xx-large;
}

.women-in-tech.contents-container .content-container:nth-of-type(even ) .content-card .title-content-container .title{
  text-align: end;
}

.women-in-tech.contents-container .content-container .content-card .title-content-container .content{
  color:black ;
  font-weight: 400;
  font-size: large;
  opacity: 0.9;
}
.accordion-container{

}
.accordion-container .title-container{
  width: 100%;
  text-align: center;
  margin-bottom: 32px;
  font-size: xx-large;
  font-weight: 600;
  color:#422166 ;
  
}
.custom-accordion{
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 2px;
}
.custom-accordion .head{
  font-size: 18px;
}
.custom-accordion .body .education-content-list{
  columns: 2;
}
.custom-accordion .body .education-content-list .item{
  font-size: large;
  font-weight: 500;
  color: #f5f5f5;
}
.custom-accordion .head .MuiTypography-root{
 color: white!important;
 font-weight: 500;
 font-size: larger;

}
.custom-accordion .body .MuiTypography-root{
  color: white!important;
  font-size: medium;
 
 }
.custom-accordion.Mui-expanded{
  border: none!important;
  
}

.custom-accordion:nth-child(2){
  background: linear-gradient(to bottom, rgb(96, 51, 139), rgba(96, 51, 139, 0.90));

}

.custom-accordion:nth-child(3){
  background: linear-gradient(to bottom, rgba(96, 51, 139, 0.90), rgba(96, 51, 139, 0.80));

}
.custom-accordion:nth-child(4){
  background: linear-gradient(to bottom, rgba(96, 51, 139, 0.80), rgba(96, 51, 139, 0.70));

}
.custom-accordion:nth-child(5){
  background: linear-gradient(to bottom, rgb(96, 51, 139, 0.70), rgba(96, 51, 139, 0.60));

}
.custom-accordion:nth-child(6){
  background: linear-gradient(to bottom, rgb(96, 51, 139, 0.60), rgba(96, 51, 139, 0.50));
}
.project-responsible-container {
  margin: 60px 0;
}
.project-responsible-container .title {
  width: 100%;
  text-align: center;
  justify-content: center;
  margin-bottom: 32px;
  font-size: xx-large;
  font-weight: 600;
  color:#422166 ;
}
.project-responsible{
  justify-content: space-between;
  flex-direction: row;
}
.project-responsible .avatar-container {
  border-radius: 10px;
  width: 200px;
  height: 200px;
  max-height: 200px;
  max-width: 100%;
}

.project-responsible .avatar-container .avatar-frame{
  border-radius: 10px;
  overflow: hidden;
  width: 200px;
  max-height: 200px;
}
.project-responsible .avatar-info{
  position: absolute;
  background-color: #422166;
  height: 100px;
  transform: translateY(-50px);
  padding: 10px;
  width: 200px;
}
.project-responsible .avatar-container .avatar-frame .avatar-title{
  position: absolute;
  z-index: 2000;
  padding:5px;
  font-weight: 400;
  letter-spacing: 1.5px;
  font-size: 14px;
  color: white;
  background-color:#422166 ;
  border-radius: 10px 0 10px 0;
  
}
.project-responsible .avatar-container .avatar-frame .avatar{
  height: 100%;
  width: 100%;
  object-fit: cover; /* Resim boyutlarını bozmadan tamamen kaplamak için */
  position: relative;

}
.project-responsible .about-social-container{
  width: calc(100% - 250px);
  min-width: min(100%, 700px);
}
.project-responsible .about-social-container .about-title{
  font-weight: 500;
  font-size: large;
  color: #422166;
  opacity: 0.7;
  margin-bottom: 10px;
}
.project-responsible .about-social-container .about-content{
  color: black;
  font-weight: 400;
}
.project-responsible .about-social-container .social-container{
  display: flex;
  flex-direction: row;
  justify-content: end;
}
.project-responsible .about-social-container .social-container .social-list{
  width: min-content!important; 
}
.project-responsible .about-social-container .social-container .social-list .item{
  color: #422166;
  font-size: 30px;
}
.project-responsible .about-social-container .social-container .social-list .item:hover{
  opacity: 0.8;
}
.form{
  height: 560px;
}
.project-footer{
  width: 100%;
  display: flex;
  justify-content: center;
}
.project-footer .logo-container{

}
.project-footer .logo-container .footer-logo{
  height: 100px;
}

/* Woman In Tech  CSS End*/