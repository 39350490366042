.card-big-shadow {
  max-width: 800px;
  position: relative;
}

.card-offers {
  border-radius: 15px;
  box-shadow: 0 2px 2px rgba(204, 197, 185, 0.5);
  background-color: #ffffff;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
}

.card-offers.card-just-text .content .title {
  text-align: center;
}

.card-offers .content {
  padding: 10px 10px 10px 10px;
}

.card-offers .category,
.card-offers .label {
  font-size: 10px;
  margin-bottom: 0px;
}
.card-big-shadow:before {
  background-image: url("http://static.tumblr.com/i21wc39/coTmrkw40/shadow.png");
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  bottom: -12%;
  display: block;
  left: -12%;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
}

.card-offers .description {
  color: #66615b;
}
.content-card {
  margin-top: 30px;
}
.content-card p {
  color: #66615b;
}

.content-card h4 {
  margin-top: 10px;
  margin-bottom: 15px;
  font-size: 20px;
}
