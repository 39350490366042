pre .hljs-comment,
pre .hljs-quote {
  color: #616161;
}

pre .hljs-variable,
pre .hljs-template-variable,
pre .hljs-attribute,
pre .hljs-tag,
pre .hljs-name,
pre .hljs-regexp,
pre .hljs-link,
pre .hljs-name,
pre .hljs-selector-id,
pre .hljs-selector-class {
  color: #f98181;
}

pre .hljs-number,
pre .hljs-meta,
pre .hljs-built_in,
pre .hljs-builtin-name,
pre .hljs-literal,
pre .hljs-type,
pre .hljs-params {
  color: #fbbc88;
}

pre .hljs-string,
pre .hljs-symbol,
pre .hljs-bullet {
  color: #b9f18d;
}

pre .hljs-property {
  color: #f1908d;
}

pre .hljs-title,
pre .hljs-section {
  color: #faf594;
}

pre .hljs-keyword,
pre .hljs-selector-tag {
  color: #70cff8;
}

pre .hljs-emphasis {
  font-style: italic;
}

pre .hljs-strong {
  font-weight: 700;
}
