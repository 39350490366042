.fh5co-more-contact {
  background: #fafafa;
}
.form-group {
  margin: 10px;
}
.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
  background: transparent;
  border: 2px solid rgba(0, 0, 0, 0.1);
  height: 50px;
  font-size: 18px;
  font-weight: 300;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  border-radius: 7px;
}
.form-control:active,
.form-control:focus {
  outline: none;
  box-shadow: none;
  border-color: #228896;
}
#message {
  height: 135px;
}
#map {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
}

.fh5co-text p {
  font-family: "Roboto", Arial, sans-serif;
  font-style: normal;
  font-size: 15px;
}

.fh5co-text a {
  text-decoration: none;
}

.validation {
  color: #ee8105;
  font-weight: 500;
  margin-left: 5px;
  margin-bottom: 0;
  padding: 0;
  min-height: 25px;
}
.centered {
  justify-content: center;
  align-items: center;
  display: flex;
}

.image-logo {
  width: 350px;
  height: 350px;
}
