.fh5co-feature {
  text-align: left;
  width: 100%;
  float: left;
  margin-bottom: 40px;
  position: relative;
}
.fh5co-feature .fh5co-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
  display: table;
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}
.fh5co-feature .fh5co-icon i {
  display: table-cell;
  vertical-align: middle;
  color: #228896;
  font-size: 40px;
  height: 100px;
}
@media screen and (max-width: 1200px) {
  .fh5co-feature .fh5co-icon i {
    font-size: 40px;
  }
}
.fh5co-feature .fh5co-text {
  padding-left: 120px;
  width: 100%;
  font-family: "Roboto", Arial, sans-serif;
  font-style: normal;
}

.fh5co-feature .btn-service {
  font-size: 10px;
  border-radius: 15px;
  padding-inline: 5px;
}

.fh5co-feature .fh5co-text h2,
.fh5co-feature .fh5co-text h3 {
  margin: 0;
  padding: 0;
}
.fh5co-feature .fh5co-text p {
  opacity: 50%;
}
.fh5co-feature .fh5co-text h3 {
  font-weight: 500;
  margin-bottom: 20px;
  color: rgba(0, 0, 0, 0.8);
  font-size: 14px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}
.fh5co-feature.fh5co-feature-sm .fh5co-text {
  margin-top: 30px;
}
.fh5co-feature.fh5co-feature-sm .fh5co-icon i {
  color: #228896;
  font-size: 40px;
}
@media screen and (max-width: 1200px) {
  .fh5co-feature.fh5co-feature-sm .fh5co-icon i {
    font-size: 28px;
  }
}
