.img-common {
  border-radius: 0 50% 50% 50%;
  height: 90%;
  width: 100%;
}

.centered-items {
  align-items: center;
  justify-content: center;
  display: flex;
}
li {
  margin-bottom: 10px;
}
