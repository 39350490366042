.full-img {
  display: flex;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.logo-img {
  max-height: 200px;
}

#fh5co-hero {
  width: 100%;
  float: left;
  clear: both;
}

#fh5co-hero .btn.btn-primary {
  padding: 14px 30px !important;
}
#fh5co-hero .btn {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #fff;
  padding: 22px 30px !important;
  border: none;
  font-weight: 500;
}
#fh5co-hero .btn.btn-learn {
  background: #fff;
  color: #000;
}
#fh5co-hero .btn.btn-learn:hover {
  color: #fff;
}
#fh5co-hero .carousel-caption {
  top: 50%;
  transform: translateY(-50%);
  bottom: initial;
}
#fh5co-hero .carousel-caption > .slider-text-inner {
  display: table-cell;
  vertical-align: middle;
  min-height: 700px;
  padding: 2em;
}
.item {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
@media screen and (max-width: 768px) {
  #fh5co-hero .carousel-caption > .slider-text-inner {
    text-align: center;
  }
}
#fh5co-hero .carousel-caption > .slider-text-inner h1,
#fh5co-hero .carousel-caption > .slider-text-inner h2 {
  margin: 0;
  padding: 0;
  color: #051146;
  font-family: "Roboto", Arial, sans-serif;
  overflow: auto;
}
#fh5co-hero .carousel-caption > .slider-text-inner h1 {
  margin-bottom: 20px;
  font-size: 52px;
  line-height: 1.3;
  font-weight: 300;
}

#fh5co-hero .carousel-caption > .slider-text-inner h2 {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 30px;
  font-weight: 300;
}

#fh5co-hero .carousel-caption > .slider-text-inner h4 {
  font-size: 20px;
  line-height: 1.5;
  margin-bottom: 30px;
  font-weight: 300;
  color: #051146;
}

#fh5co-hero .carousel-caption > .slider-text-inner h2 a {
  color: rgba(34, 136, 150, 0.8);
  border-bottom: 1px solid rgba(34, 136, 150, 0.7);
}
#fh5co-hero .carousel-caption > .slider-text-inner .heading-section {
  font-size: 50px;
}
@media screen and (max-width: 768px) {
  #fh5co-hero .carousel-caption > .slider-text-inner .heading-section {
    font-size: 30px;
  }
}
#fh5co-hero .carousel-caption > .slider-text-inner s .fh5co-lead {
  font-size: 20px;
  color: #051146;
}

#fh5co-hero .carousel-caption > .slider-text-inner .btn {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #fff;
  padding: 22px 30px !important;
  border: none;
  font-weight: 500;
}
#fh5co-hero .carousel-caption > .slider-text-inner .btn.btn-learn {
  background: #fff;
  color: #000;
}
#fh5co-hero .carousel-caption > .slider-text-inner .btn.btn-learn:hover {
  color: #fff;
}
@media screen and (max-width: 768px) {
  #fh5co-hero .carousel-caption > .slider-text-inner .btn {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  #fh5co-hero .carousel-caption > .slider-text-inner h1 {
    font-size: 32px;
    font-weight: 400;
    font-family: "Roboto", Arial, sans-serif;
  }
}

@media screen and (max-width: 768px) {
  #fh5co-hero .carousel-caption > .slider-text-inner h4 {
    font-size: 15px;
    font-weight: 300;
    font-family: "Roboto", Arial, sans-serif;
  }
}
@media screen and (max-width: 768px) {
  .logo-img {
    max-height: 80px;
  }
}
