.success-msg,
.warning-msg,
.error-msg {
  margin: 10px 0;
  padding: 10px;
  border-radius: 3px 3px 3px 3px;
}

.success-msg {
  color: #270;
  background-color: #dff2bf;
}
.warning-msg {
  color: #9f6000;
  background-color: #feefb3;
}
.error-msg {
  color: #d8000c;
  background-color: #ffbaba;
}

.msg i {
  margin-right: 5px;
}
