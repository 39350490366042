:root {
  --red: hsl(0, 78%, 62%);
  --cyan: hsl(180, 62%, 55%);
  --orange: hsl(34, 97%, 64%);
  --blue: hsl(212, 86%, 64%);
  --varyDarkBlue: hsl(234, 12%, 34%);
  --grayishBlue: hsl(229, 5%, 53%);
  --veryLightGray: hsl(0, 0%, 98%);
  --weight1: 200;
}

.attribution {
  font-size: 11px;
  text-align: center;
}
.attribution a {
  color: hsl(228, 45%, 44%);
}

.box h1:first-of-type {
  font-weight: var(--weight1);
  color: var(--varyDarkBlue);
}

.box h1:last-of-type {
  color: var(--varyDarkBlue);
}

@media (max-width: 400px) {
  h1 {
    font-size: 1.5rem;
  }
}

.box p {
  color: var(--grayishBlue);
}
.box .icon-product-box {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.box {
  border-radius: 5px;
  box-shadow: 0px 20px 10px -20px var(--grayishBlue);
  padding: 30px;
  margin: 10px;
  display: flex;
  justify-content: space-between;
}

.box img {
  float: right;
  max-width: 50px;
}

@media (max-width: 950px) and (min-width: 450px) {
  .box {
    text-align: center;
  }
}

.cyan {
  border-top: 3px solid var(--cyan);
}
.red {
  border-top: 3px solid var(--red);
}
.blue {
  border-top: 3px solid var(--blue);
}
.orange {
  border-top: 3px solid var(--orange);
}

.box h2 {
  color: var(--varyDarkBlue);
  font-weight: var(--weight3);
}

.box h5 {
  color: var(--varyDarkBlue);
  font-weight: var(--weight3);
  margin: 10px 0px 5px 0px;
}


/* 02.05.2023 ProductCardWithButtons Begining - Murat Yilmaz*/
.product-link-component{
  text-decoration: none;
  color: white!important;
  padding-left: 10px;
}
.product-link-component:hover{
  color: black!important;
}
.product-link-container{
  border-radius: 10px;
  display: inline-block;
  font-size: medium;
  font-weight: 500;
  padding-right: 10px;
  height: 35px;
  width: auto;
}
.product-link{
  display: flex;
  align-items: end;
}
.cyan .product-link-container {
  background-color: var(--cyan);
}
.red .product-link-container {
  background-color: var(--red);
}
.blue .product-link-container {
  background-color: var(--blue);
}
.orange .product-link-container {
  background-color: var(--orange);
}
.link-icon-container{
  padding: 3px;
  height: 35px;
  background-color: white;
  border-radius: 10px;
  margin-right: 10px;
}
.link-icon{
  height: 100%;
}
.link-text{
  padding-bottom: 3px;
  width: auto;
}


/*ProductCardWithButtons Finish */