.spinner-div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 300px;
  margin-right: 150px;
}
.show-more {
  opacity: 0.6;
  display: flex;
  justify-content: center;
  align-items: center;
}

.show-more button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  font-family: "Roboto" sans-serif;
  font-size: 18px;
  font-weight: 300;
  border: none;
  color: black;
  background: none;
}

.show-more button:hover {
  color: gray;
}

.blog-entry {
  width: 100%;
  float: left;
  background: #fff;
}

.blog-listing {
  padding-top: 30px;
  padding-bottom: 30px;
}
.gray-bg {
  background-color: #f5f5f5;
}

/* Blog Sidebar
-------------------*/

.blog-aside {
  position: fixed;
  right: 40px;
  overflow: hidden;
  margin-left: 40px;
}
@media (max-width: 1200px) {
  .blog-aside {
    position: relative;
  }
}
.blog-aside .widget {
  border-radius: 15px;
  overflow: hidden;
  background: #ffffff;
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;
  display: inline-block;
  vertical-align: top;
}
.blog-aside .widget-body {
  padding: 15px;
}
.blog-aside .widget-title {
  padding: 15px;
  border-bottom: 1px solid #eee;
}
.blog-aside .widget-title h3 {
  font-size: 20px;
  font-weight: 700;
  color: #202f38;
  margin: 0;
}
.blog-aside .widget-author .media {
  margin-bottom: 15px;
}
.blog-aside .widget-author p {
  font-size: 16px;
  margin: 0;
}
.blog-aside .widget-author .avatar {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  overflow: hidden;
}
.blog-aside .widget-author h6 {
  font-weight: 600;
  color: #555555;
  font-size: 22px;
  margin: 0;
}
.blog-aside .post-aside {
  margin-bottom: 15px;
}
.blog-aside .post-aside .post-aside-title h5 {
  margin: 0;
}
.blog-aside .post-aside .post-aside-title a {
  font-size: 18px;
  color: #20247b;
  font-weight: 600;
}
.blog-aside .post-aside .post-aside-meta {
  padding-bottom: 10px;
}
.blog-aside .post-aside .post-aside-meta a {
  color: #6f8ba4;
  font-size: 12px;
  text-transform: uppercase;
  display: inline-block;
  margin-right: 10px;
}
.blog-aside .latest-post-aside + .latest-post-aside {
  border-top: 1px solid #eee;
  padding-top: 15px;
  margin-top: 15px;
}
.blog-aside .latest-post-aside .lpa-right {
  width: 90px;
}

.blog-aside .latest-post-aside .lpa-left {
  padding-right: 15px;
}
.blog-aside .latest-post-aside .lpa-title h5 {
  margin: 0;
  font-size: 15px;
}
.blog-aside .latest-post-aside .lpa-title a {
  color: #92967d;
  font-weight: 600;
}
.blog-aside .latest-post-aside .lpa-meta a {
  color: #6f8ba4;
  font-size: 12px;
  text-transform: uppercase;
  display: inline-block;
  margin-right: 10px;
}

.blog-single {
  padding-top: 10px;
}

.article {
  border-radius: 15px;
  overflow: hidden;
  background: #ffffff;
  padding: 15px;
  margin: 15px 0 30px;
}
.article .article-title {
  padding: 15px 0 20px;
}
.article .article-title h6 {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 20px;
}
.article .article-title h6 a {
  text-transform: uppercase;
  color: #92967d;
  border-bottom: 1px solid #fc5356;
}
.article .article-title h2 {
  color: #555555;
  font-weight: 500;
  font-size: 30px;
  margin-bottom: 10px;
}
.article .article-title .media {
  padding-top: 10px;
  border-bottom: 1px dashed #ddd;
  padding-bottom: 20px;
}
.article .article-title .media .avatar {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  overflow: hidden;
}
.article .article-title .media .media-body {
  padding-left: 8px;
}
.article .article-title .media .media-body label {
  font-weight: 500;
  color: #6e7c7c;
  margin: 0;
}
.article .article-title .media .media-body span {
  display: block;
  font-size: 12px;
}
.article .article-content h1,
.article .article-content h2,
.article .article-content h3,
.article .article-content h4,
.article .article-content h5,
.article .article-content h6 {
  color: #555555;
  font-weight: 400;
  margin-bottom: 15px;
  font-size: 25px;
}

img {
  max-width: 100%;
}
img {
  vertical-align: middle;
  border-style: none;
}

.section-title h2 {
  font-weight: 700;
  color: #20247b;
  font-size: 45px;
  margin: 0 0 15px;
  border-left: 5px solid #fc5356;
  padding-left: 15px;
}
.section-title {
  padding-bottom: 45px;
}

.px-btn {
  padding: 0 50px 0 20px;
  line-height: 60px;
  position: relative;
  display: inline-block;
  color: #20247b;
  background: none;
  border: none;
}
.px-btn:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  border-radius: 30px;
  background: transparent;
  border: 1px solid rgba(252, 83, 86, 0.6);
  border-right: 1px solid transparent;
  -moz-transition: ease all 0.35s;
  -o-transition: ease all 0.35s;
  -webkit-transition: ease all 0.35s;
  transition: ease all 0.35s;
  width: 60px;
  height: 60px;
}
.px-btn .arrow {
  width: 13px;
  height: 2px;
  background: currentColor;
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 25px;
}
.px-btn .arrow:after {
  width: 8px;
  height: 8px;
  border-right: 2px solid currentColor;
  border-top: 2px solid currentColor;
  content: "";
  position: absolute;
  top: -3px;
  right: 0;
  display: inline-block;
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.socials {
  margin-top: 15px;
}

.socials i {
  margin: 20px 15px 0px 0px;
}

.socials a {
  color: black;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
.socials a:hover,
.socials a:active,
.socials a:focus {
  color: #f53a01;
  outline: none;
  text-decoration: none !important;
}

.tag-cloud p {
  padding: 4px 15px;
  font-size: 12px;
  color: #ffffff;
  background: #553551;
  border-radius: 3px;
  margin-right: 4px;
  margin-bottom: 4px;
}

.article .tag-cloud {
  padding-top: 10px;
}
.disable-me {
  pointer-events: none;
}
