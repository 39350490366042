.input {
  width: 95%;
  padding: 8px;
  text-align: center;
  font-size: 15px;
  border: none;
  opacity: 80%;
}

.input-icons i {
  position: absolute;
  opacity: 80%;
}

.input-icons .input:focus {
  opacity: 100%;
  outline: none;
}

.icon {
  padding: 8px;
  color: grey;
  min-width: 20px;
  text-align: center;
}
