/* Reset CSS */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
}

header {
    text-align: center;
    padding: 20px 0;
    color: #fff;
}

main {
    padding: 20px;
}
.page-title{
    font-weight: 700;
}
.video-container {
    position: relative;
    width: 60%;
    padding-bottom: 35.25%; /* 16:9 Aspect Ratio */
    margin-bottom: 20px;
    margin: 0 auto;

}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 90%;
    height: 100%;
}

section {
    margin-bottom: 30px;
}

h2 {
    color: #333;
}

.highlighted {
    background-color: #f0f8ff; /* AliceBlue */
    padding: 20px;
    border-radius: 10px;
}
.highlighted h2 {
    font-weight: 700;
}
.highlighted p {
    font-weight: 700;
    line-height: 1.8;
    letter-spacing: 1.25px;
}
