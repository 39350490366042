.blog-entry {
  width: 100%;
  float: left;
  background: #fff;
  border-radius: 15px;
}
@media screen and (max-width: 768px) {
  .blog-entry {
    margin-bottom: 30px;
  }
}

.blog-entry .blog-img {
  width: 100%;
  float: left;
  overflow: hidden;
  position: relative;
  z-index: 1;
  margin-bottom: 25px;
}
.blog-entry .blog-img img {
  position: relative;
  max-width: 100%;
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
  -webkit-transition: 0.9s;
  -o-transition: 0.9s;
  transition: 0.9s;
}
.blog-entry .desc {
  padding: 25px;
}
.blog-entry .desc h3 {
  font-size: 15px;
  text-transform: uppercase;
  margin-bottom: 10px;
  letter-spacing: 1px;
  line-height: auto;
  color: #000;
  font-family: "Roboto", Arial, sans-serif;
  font-weight: 400;
}

.blog-entry .desc p {
  font-size: 15px;
  line-height: auto;
  font-family: "PT Sans", sans-serif;
  color: #000;
  opacity: 0.5;
  margin: 0;
}

.blog-entry .desc span {
  display: block;
  margin-bottom: 20px;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: rgba(0, 0, 0, 0.4) !important;
}

.blog-entry .desc .lead {
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #000;
}
.blog-entry:hover .blog-img img {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
